import React from 'react'
import "../assets/styles/footer.css"
import { Link } from 'react-router-dom'
import { FaFacebook, FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa'
import logo from "../assets/images/logo.jpeg"
import sibm from "../assets/images/sibm.png"

const Footer = () => {
    return (
        <div className="footer_main">
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12">
                            <div className="row">
                                <div className="col-lg-3 col-sm-12">
                                    <h3>About Us</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam autem laborum delectus consectetur dolore dolores, tempora magni expedita repellat in?</p>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <h3>About Us</h3>
                                    <ul className='course_lists'>
                                        <li><Link >About Us</Link></li>
                                        <li><Link to="">Mission & Vision</Link></li>
                                        <li><Link to="">Admission</Link></li>
                                        <li><Link to="">Faculty Corner</Link></li>
                                        <li><Link to="">Approval & Affiliation</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <h3>Academics</h3>
                                    <ul className='course_lists'>
                                        <li><Link>Admission</Link></li>
                                        <li><Link >Affiliation Letter</Link></li>
                                        <li><Link >Letter of Approval</Link></li>
                                        <li><Link >Faculty</Link></li>
                                        <li><Link >Academic Calender</Link></li>
                                        <li><Link>Question Bank</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <h3>Learning Experience</h3>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus illo commodi nisi corrupti quisquam exercitationem rem placeat adipisci culpa praesentium.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="footer_web_logo">
                                <Link> <img src={sibm} alt="" style={{ width: "100px" }} />
                                </Link>

                            </div>
                            <div className="footer_desc">
                                <p>© 2024 SIBM. All rights reserved</p>
                                <ul className='footer_bott_menu'>
                                    <li>TERMS OF USE</li>
                                    <li>|</li>
                                    <li>PRIVACY POLICY</li>
                                    <li>|</li>
                                    <li>GENERIC</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="footer_bottom">
                <div className="container">
                    <div className="row justify-content-center" style={{ width: "100%" }}>
                        <div className="col-lg-6 col-sm-12">
                            <div class="connect-us">
                                <h3>Connect with Us</h3>
                                <ul className='social_media_icons'>
                                    <li><FaFacebook fontSize="25px" /></li>
                                    <li><FaTwitterSquare fontSize="25px" /></li>
                                    <li><FaInstagramSquare fontSize="25px" /></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-sm-12" >
                            <Link><em style={{ color: "green", fontSize: "24px", fontWeight: "bold" }}>
                            </em></Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer