import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import "../assets/styles/home.css"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import banner1 from "../assets/images/banner/banner1.webp"
import banner2 from "../assets/images/banner/banner2.webp"
import banner3 from "../assets/images/banner/banner3.webp"
import about from "../assets/images/about.jpg"
import ico_1 from "../assets/images/icons/ico_1.jpg";
import ico_2 from "../assets/images/icons/ico_2.jpg";
import ico_3 from "../assets/images/icons/ico_3.jpg";
import ico_4 from "../assets/images/icons/ico_4.jpg";
import ico_5 from "../assets/images/icons/ico_5.jpg";
import ico_6 from "../assets/images/icons/ico_6.jpg";
import g1 from "../assets/images/gallery/g1.jpg"
import g2 from "../assets/images/gallery/g2.jpg"
import g3 from "../assets/images/gallery/g3.jpg"
import g4 from "../assets/images/gallery/g4.jpg"
import g5 from "../assets/images/gallery/g5.jpg"
import g6 from "../assets/images/gallery/g6.jpg"
import g7 from "../assets/images/gallery/g7.jpg"
import g8 from "../assets/images/gallery/g8.jpg"
import g9 from "../assets/images/gallery/g9.jpg"
import g10 from "../assets/images/gallery/g10.jpg"
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { CiTrophy } from 'react-icons/ci';
import { PiStudent } from 'react-icons/pi';
import { FaExpandArrowsAlt, FaUniversity } from 'react-icons/fa';
import { FaPeopleRoof } from 'react-icons/fa6';


const Home = () => {



    const [modalImage, setModalImage] = useState("")
    const [currentIndex, setCurrentIndex] = useState("")

    const banners = [

        {
            img: banner2,
            txt: "Welcome to SIBM",
            subtext: "Where Passion Meets Profession"

        },
        {
            img: banner1,
            txt: "Welcome to SIBM",
            subtext: "Empowering Future Leaders"

        },
        {
            img: banner3,
            txt: "Welcome to SIBM",
            subtext: "A Legacy Of Excellance"

        }
    ]




    const galleryImages = [
        {
            img: g1
        },
        {
            img: g2
        },
        {
            img: g3
        },
        {
            img: g4
        },
        {
            img: g5
        },
        {
            img: g6
        },
        {
            img: g7
        },
        {
            img: g8
        },
        {
            img: g9
        },
        {
            img: g10
        },
    ]



    const openModal = (index) => {
        setModalImage(galleryImages[index].img);
        console.log(modalImage)
        setCurrentIndex(index)
    }


    const closeModal = () => {
        setModalImage("");
    };


    const prevImage = () => {
        if (currentIndex === 0) {
            // If current index is the first image, wrap around to the last image
            const lastIndex = galleryImages.length - 1;
            setModalImage(galleryImages[lastIndex].img);
            setCurrentIndex(lastIndex);
        } else {
            // Otherwise, go to the previous image
            const newIndex = currentIndex - 1;
            setModalImage(galleryImages[newIndex].img);
            setCurrentIndex(newIndex);
        }
    }


    const nextImage = () => {
        if (currentIndex === galleryImages.length - 1) {
            // If current index is the last image, wrap around to the first image
            setModalImage(galleryImages[0].img);
            setCurrentIndex(0);
        } else {
            // Otherwise, go to the next image
            const newIndex = currentIndex + 1;
            setModalImage(galleryImages[newIndex].img);
            setCurrentIndex(newIndex);
        }

    }

    return (
        <>
            <div className="main_home_section">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {banners.map((banner) => (
                        <SwiperSlide key={banner.id}>
                            <div className="slideContent">
                                <img src={banner.img} alt={banner.txt} className="slideImage" />

                                <div className="textOverlay">
                                    <h1>{banner.txt}</h1>
                                    <p>{banner.subtext}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>


            </div>



            <div className="about_section mtb-40 padding-lg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-sm-12">
                            <img src={about} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-7 col-sm-12">
                            <div className="about_sec" style={{ marginLeft: "20px" }}>
                                <span class="sm-head">the Online Education portal</span>
                                <h2>SIBM Online</h2>
                                <p>
                                    SIBM Online is a cutting-edge online education portal offering high-quality courses designed to enhance professional skills and knowledge. Specializing in management and business education, the platform provides flexible, interactive learning experiences that cater to both working professionals and students. With expertly crafted curricula and experienced faculty, SIBM Online ensures that learners receive practical insights and up-to-date knowledge in their respective fields.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="our_importance mtb-40 padding-lg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_1} alt="" />
                                <div className="imp_card_info">
                                    <h3>Malleable Study Time</h3>
                                    <p>Study material available online 24/7. Study in your free time, no time management issues, perfect balance between work and study time.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_2} alt="" />
                                <div className="imp_card_info">
                                    <h3>Placement Assistance</h3>
                                    <p>SIBMUniversity Online has access to all of SIBMGroup’s placement resources and alumni network, through which thousands of job opportunities are generated</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_3} alt="" />
                                <div className="imp_card_info">
                                    <h3>Easy To Access</h3>
                                    <p>There is easy accessibility to online help in terms of online teachers and online forums. Teachers can be contacted with the help of video chats and e-mails.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_4} alt="" />
                                <div className="imp_card_info">
                                    <h3>Study on the Mobile</h3>
                                    <p>LMS that is easily accessible on a number of devices such as mobile phones, I-pads, computers and other such devices. Availability of ready reckoners such that students can remember the key points of the session learnt.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_5} alt="" />
                                <div className="imp_card_info">
                                    <h3>Get an Innovative,
                                        In-depth Transition</h3>
                                    <p>The transition to an environment of learning becomes easy with the availability of multiple sources of learning such as text books, power-point presentations, and story boards on various subjects.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_6} alt="" />
                                <div className="imp_card_info">
                                    <h3>Practical & Interactive
                                        Participation</h3>
                                    <p>Assessments and interactivities are given at the end of every session such that the practical application of theory learnt can be gauged.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <section class="why-choose padding-lg mtb-40">

                <div class="container">
                    <h2 className='why-choose-title'>
                        <span>The Numbers Say it All</span>
                        Why Choose Us         </h2>
                    <ul class="our-strength">
                        <li>
                            <div class="icon"><CiTrophy fontSize="50px" /></div>
                            <span class="counter">8</span>
                            <div class="title">Certified Courses</div>
                        </li>
                        <li>
                            <div class="icon"><PiStudent fontSize="50px" /></div>
                            <span class="counter">64,286</span>
                            <div class="title">Students Enrolled </div>
                        </li>
                        <li>
                            <div class="icon"><FaUniversity fontSize="50px" /></div>
                            <div class="couter-outer"><span class="counter">23</span><span>%</span></div>
                            <div class="title">Passing to Universities</div>
                        </li>
                        <li>
                            <div class="icon"><FaPeopleRoof fontSize="50px" /></div>
                            <div class="couter-outer"><span class="counter">23</span><span>%</span></div>
                            <div class="title">Satisfied Parents</div>
                        </li>
                    </ul>
                </div>
            </section>


            <section className="gallery mtb-40 padding-lg">
                <div className="container">
                    <div className="gallery_sec text-center">
                        <span>Our campus have a lot to offer for our students
                        </span>
                        <h1>TAKE A CAMPUS TOUR</h1>
                    </div>
                    <div className="row">
                        {
                            galleryImages.map((item, index) => {
                                return (
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="gallery_card">
                                            <img src={item.img} alt="" srcset="" style={{ width: "100%" }} onClick={() => openModal(index)} />


                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>


                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-8 col-sm-12">
                            {modalImage && <div id="imageModal" class="imgmodal">
                                <span class="close" onClick={closeModal}>&times;</span>
                                <img class="modal-content" src={modalImage} id="modalImage" />
                                <div class="caption" id="caption"></div>
                                <button class="prev" onclick={prevImage}>&#10094; Prev</button>
                                <button class="next" onclick={nextImage}>Next &#10095;</button>
                            </div>}
                        </div>
                    </div>

                </div>
            </section>

            <section class="testimonial padding-lg">

                <div class="container">
                    <div class="wrapper">
                        <h2 className='why-choose-title text-center' style={{ color: "#fff" }}>Alumini Testimonials </h2>

                    </div>

                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="testi_texts">
                                <p>The instructors at this institute are incredibly knowledgeable and approachable. They genuinely care about students' progress and provide excellent guidance. The campus is well-equipped with modern facilities, making it a fantastic place to study!</p>
                                <p className="test_name">
                                    Suresh
                                </p>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testi_texts">
                                <p>I’ve had an amazing experience at this institute. The atmosphere is very positive, and there is a strong focus on both academic and personal growth. The teachers are supportive, and the extracurricular activities help in building skills beyond the classroom.</p>
                                <p className="test_name">
                                    Jay
                                </p>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testi_texts">
                                <p>The curriculum here is practical and geared towards preparing students for real-world challenges. I’ve learned so much that I can directly apply in my career. The institute also offers excellent internship opportunities that have been invaluable for gaining experience.</p>
                                <p className="test_name">
                                    Riya
                                </p>
                            </div>
                        </SwiperSlide>


                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Home