import React from 'react'
import "../assets/styles/contact.css"

const Contact = () => {
    return (
        <>
            <section className="contact_wrapper">
                <div className="contact_wrapper_overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="content">
                                <h1>Contact Us</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="form_section padding-lg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-12">
                            <h2 className='text-center'>Reach Out to Us</h2>
                        </div>
                    </div>
                    <div className="form_wrapper">
                        <form className="form">
                            <div className="row justify-content-center">
                                <div className="col-lg-5 col-sm-12">
                                    <div className="con_card">
                                        <span>
                                            <input type="text" name="first_name" value="" size="40" class="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="First Name" />
                                        </span>
                                        <span>
                                            <input type="text" name="first_name" value="" size="40" class="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="Email" />
                                        </span>
                                        <span>
                                            <textarea name="" id="" class="wpcf7-form-control wpcf7-text"
                                                placeholder='Message' ></textarea>
                                        </span>
                                        <div class="button-wrapper"> <a href="https://shtheme.com/demosd/edumart/?page_id=690" class="btn">apply now <span class="icon-more-icon"></span></a> </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-12">
                                    <div className="con_card">
                                        <span>
                                            <input type="text" name="first_name" value="" size="40" class="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="Last Name" />
                                        </span>
                                        <span>
                                            <input type="text" name="first_name" value="" size="40" class="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="Phone Number" />
                                        </span>

                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>



        </>
    )
}

export default Contact