import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/styles/global.css"
import Home from './pages/Home';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import ResponsiveNavbar from './layout/ResponsiveNavbar';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Engineering from './pages/courses/Engineering';
import MBA from './pages/courses/MBA';
import Teaching from './pages/courses/BCA';
import Medical from './pages/courses/Medical';
import BBA from './pages/courses/BBA';
import BCA from './pages/courses/BCA';
import MissionVision from './pages/MissionVision';
import Testimonials from './pages/Testimonials';

const App = () => {
  return (
    <Router>
      <Navbar />
      <ResponsiveNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/mission-vision" element={<MissionVision />} />
        <Route path='/course-detail/engineering' element={<Engineering />} />
        <Route path='/course-detail/mba' element={<MBA />} />
        <Route path='/course-detail/bba' element={<BBA />} />
        <Route path='/course-detail/bca' element={<BCA />} />
        <Route path='/course-detail/medical' element={<Medical />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/testimonials' element={<Testimonials />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App