import React from 'react'
import "../assets/styles/about.css";
import { GrCertificate } from 'react-icons/gr';
import { PiStudentDuotone } from 'react-icons/pi';
import { LiaUniversitySolid } from 'react-icons/lia';
import { IoIosPeople } from 'react-icons/io';
import ico_1 from "../assets/images/icons/ico_1.jpg";
import ico_2 from "../assets/images/icons/ico_2.jpg";
import ico_3 from "../assets/images/icons/ico_3.jpg";
import ico_4 from "../assets/images/icons/ico_4.jpg";
import ico_5 from "../assets/images/icons/ico_5.jpg";
import ico_6 from "../assets/images/icons/ico_6.jpg";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const About = () => {
    return (
        <>
            <section className="about_wrapper">
                <div className="about_wrapper_overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-12">
                            <div className="content">
                                <h2>About Us</h2>
                                <p>        Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit est sunt dolore illum pariatur, incidunt nesciunt autem facilis quaerat ipsa quisquam reprehenderit voluptatem tempore sint saepe soluta quod! Consectetur, ab.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="about inner padding-lg">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 left-block">
                            <h2>Who we are</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                        </div>
                        <div class="col-md-5 about-right"> <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/who-we-are-img.jpg" class="img-responsive" alt="" style={{ width: "100%" }} /> </div>
                    </div>
                </div>
            </section>


            <section class="why-choose grey-bg padding-lg">
                <div class="container">
                    <h2>
                        <span>The Numbers Say it All</span>
                        Why Choose Us            </h2>
                    <ul class="our-strength opt2">
                        <li>
                            <div class="icon"><span class=""><GrCertificate /> </span></div>
                            <span class="counter">36</span>
                            <div class="title">Certified Courses</div>
                        </li>
                        <li>
                            <div class="icon"><span><PiStudentDuotone /> </span></div>
                            <span class="counter">258,658</span>
                            <div class="title">Students Enrolled </div>
                        </li>
                        <li>
                            <div class="icon"><span ><LiaUniversitySolid /> </span></div>
                            <div class="couter-outer"><span class="counter">95</span><span>%</span></div>
                            <div class="title">Passing to Universities</div>
                        </li>
                        <li>
                            <div class="icon"><span > <IoIosPeople /></span></div>
                            <div class="couter-outer"><span class="counter">100</span><span>%</span></div>
                            <div class="title">Satisfied Parents</div>
                        </li>
                    </ul>
                </div>
            </section>


            <div className="our_importance mtb-40 padding-lg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_1} alt="" />
                                <div className="imp_card_info">
                                    <h3>Malleable Study Time</h3>
                                    <p>Study material available online 24/7. Study in your free time, no time management issues, perfect balance between work and study time.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_2} alt="" />
                                <div className="imp_card_info">
                                    <h3>Placement Assistance</h3>
                                    <p>ITM University Online has access to all of ITM Group’s placement resources and alumni network, through which thousands of job opportunities are generated</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_3} alt="" />
                                <div className="imp_card_info">
                                    <h3>Easy To Access</h3>
                                    <p>There is easy accessibility to online help in terms of online teachers and online forums. Teachers can be contacted with the help of video chats and e-mails.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_4} alt="" />
                                <div className="imp_card_info">
                                    <h3>Study on the Go</h3>
                                    <p>LMS that is easily accessible on a number of devices such as mobile phones, I-pads, computers and other such devices. Availability of ready reckoners such that students can remember the key points of the session learnt.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_5} alt="" />
                                <div className="imp_card_info">
                                    <h3>Get an Innovative,
                                        In-depth Transition</h3>
                                    <p>The transition to an environment of learning becomes easy with the availability of multiple sources of learning such as text books, power-point presentations, and story boards on various subjects.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="imp_card">
                                <img src={ico_6} alt="" />
                                <div className="imp_card_info">
                                    <h3>Practical & Interactive
                                        Participation</h3>
                                    <p>Assessments and interactivities are given at the end of every session such that the practical application of theory learnt can be gauged.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* our team */}
            <section class="browse-teacher grey-bg padding-lg">
                <div class="container">
                    <h2 className='text-center' style={{ marginBottom: "20px" }}>
                        <span>There are many ways to learn</span> <br />
                        BROWSE BY TEACHER
                    </h2>
                    <ul class="row browse-teachers-list clearfix">
                        <li class="col-xs-6 col-sm-12 col-lg-3">
                            <div className="teacher_card">
                                <figure>
                                    <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/teacher1.jpg" width="123" height="124" alt="" />
                                </figure>
                                <h3>Bruno Acselrad</h3>
                                <span class="designation">Math Teacher</span>
                                <p class="equal-hight">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                                <ul class="teachers-follow">
                                    <li><a href="https://twitter.com/"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.linkedin.com/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.skype.com/"><i class="fa fa-skype" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="col-xs-6 col-sm-12 col-lg-3">
                            <div className="teacher_card">
                                <figure>
                                    <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/teacher2.jpg" width="123" height="124" alt="" />
                                </figure>
                                <h3>Bruno Acselrad</h3>
                                <span class="designation">Senior Lecturor</span>
                                <p class="equal-hight" >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                                <ul class="teachers-follow">
                                    <li><a href="https://twitter.com/"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.linkedin.com/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.skype.com/"><i class="fa fa-skype" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="col-xs-6 col-sm-12 col-lg-3 ">
                            <div className="teacher_card">
                                <figure>
                                    <img loading="lazy" decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/teacher3.jpg" width="123" height="124" alt="" />
                                </figure>
                                <h3>Bruno Acselrad</h3>
                                <span class="designation">Development</span>
                                <p class="equal-hight">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                                <ul class="teachers-follow">
                                    <li><a href="https://twitter.com/"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.linkedin.com/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.skype.com/"><i class="fa fa-skype" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="col-xs-6 col-sm-12 col-lg-3">
                            <div className="teacher_card">
                                <figure>
                                    <img loading="lazy" decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/teacher4.jpg" width="123" height="124" alt="" />
                                </figure>
                                <h3>Bruno Acselrad</h3>
                                <span class="designation">professor</span>
                                <p class="equal-hight" >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                                <ul class="teachers-follow">
                                    <li><a href="https://twitter.com/"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.linkedin.com/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.skype.com/"><i class="fa fa-skype" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            <section class="testimonial padding-lg">

                <div class="container">
                    <div class="wrapper">
                        <h2 className='why-choose-title text-center' style={{ color: "#fff" }}>Alumini Testimonials </h2>

                    </div>

                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="testi_texts">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, animi voluptatibus soluta et assumenda sapiente officia nam esse voluptatum vitae, nemo eaque. Fugit magnam, quo porro totam maiores soluta assumenda magni quisquam similique eos dignissimos, odio dolorem facilis blanditiis sequi officiis itaque obcaecati. Earum voluptatibus architecto ratione delectus nulla inventore esse cumque doloribus provident quasi autem iusto dolorem, quo impedit.</p>
                                <p className="test_name">
                                    Sayli
                                </p>
                            </div>
                            <div id="bx-pager">
                                <a data-slide-index="0" href="" class="">
                                    <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/testimonial-thumb1.jpg" class="img-circle" alt="" />
                                </a>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testi_texts">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, animi voluptatibus soluta et assumenda sapiente officia nam esse voluptatum vitae, nemo eaque. Fugit magnam, quo porro totam maiores soluta assumenda magni quisquam similique eos dignissimos, odio dolorem facilis blanditiis sequi officiis itaque obcaecati. Earum voluptatibus architecto ratione delectus nulla inventore esse cumque doloribus provident quasi autem iusto dolorem, quo impedit.</p>
                                <p className="test_name">
                                    Ravi Kumar
                                </p>
                            </div>
                            <div id="bx-pager">
                                <a data-slide-index="0" href="" class="">
                                    <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/testimonial-thumb2.jpg" class="img-circle" alt="" />
                                </a>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testi_texts">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, animi voluptatibus soluta et assumenda sapiente officia nam esse voluptatum vitae, nemo eaque. Fugit magnam, quo porro totam maiores soluta assumenda magni quisquam similique eos dignissimos, odio dolorem facilis blanditiis sequi officiis itaque obcaecati. Earum voluptatibus architecto ratione delectus nulla inventore esse cumque doloribus provident quasi autem iusto dolorem, quo impedit.</p>
                                <p className="test_name">
                                    Riya
                                </p>
                            </div>
                        </SwiperSlide>


                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default About