import React from 'react'
import "../assets/styles/mission.css"
import mission from "../assets/images/mission.jpg"
import vision from "../assets/images/vision.jpg"
import { TiTick } from 'react-icons/ti'

const MissionVision = () => {
    return (
        <>
            <div className="mission-vision-section">
                <div className="container">

                    <h2>Vision & Mission</h2>

                </div>
            </div>


            <section className="mission_area padding-lg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="mission_img_card">
                                <img src={mission} alt="" style={{ width: "100%" }} />
                            </div>

                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="mission_content_card">
                                <div className="header">
                                    <h2>Mission</h2>
                                </div>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium aspernatur aut veritatis provident ea impedit itaque amet ipsam rerum, non quibusdam id facere quos culpa nihil dolor iure? Voluptatibus esse necessitatibus placeat hic non nemo voluptas, laudantium quaerat recusandae nam!</p>
                                <ul className='mission_content'>
                                    <li><TiTick fontSize="20px" color='#a94d0c' /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nulla ratione, nam rerum quia eum animi. Non culpa nesciunt vitae?</li>
                                    <li><TiTick fontSize="20px" color='#a94d0c' /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nulla ratione, nam rerum quia eum animi. Non culpa nesciunt vitae?</li>
                                    <li><TiTick fontSize="20px" color='#a94d0c' /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nulla ratione, nam rerum quia eum animi. Non culpa nesciunt vitae?</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="vision_area padding-lg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">

                            <div className="mission_content_card">
                                <div className="header">
                                    <h2>Vision</h2>
                                </div>
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit, nihil porro officiis maxime doloremque, aliquam deleniti quia nesciunt et doloribus at. Molestias obcaecati autem magnam reiciendis suscipit voluptate impedit voluptatem fuga praesentium. Maxime incidunt molestiae reiciendis labore vel porro officiis?</p>
                                <ul className='mission_content'>
                                    <li><TiTick fontSize="20px" color='#a94d0c' /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nulla ratione, nam rerum quia eum animi. Non culpa nesciunt vitae?</li>
                                    <li><TiTick fontSize="20px" color='#a94d0c' /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nulla ratione, nam rerum quia eum animi. Non culpa nesciunt vitae?</li>
                                    <li><TiTick fontSize="20px" color='#a94d0c' /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nulla ratione, nam rerum quia eum animi. Non culpa nesciunt vitae?</li>

                                </ul>
                            </div>


                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="vision_image_card">
                                <img src={vision} alt="" style={{ width: "100%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default MissionVision