import React, { useState } from 'react'

import { GiHamburgerMenu } from 'react-icons/gi'
import "../assets/styles/responsiveNavbar.css"
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { CiMail } from 'react-icons/ci';
import { HiOutlinePhone } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { FiMinus } from 'react-icons/fi';
import { GoPlus } from 'react-icons/go';
import sibm from "../assets/images/sibm.png"

const ResponsiveNavbar = () => {
    const [resposiveMenu, setResponsiveMenu] = useState(false);
    const [servicesMenu, setServicesMenu] = useState(null);
    const [subMenu, setSubMenu] = useState("")



    return (

        <div className="responsive_navbar">
            <div className="container">
                <nav className="responsive_navbar_section">
                    <div className="navbar_left">
                        {/* <div className="logo_area">
                            <img src={cs_logo_dark} alt="" />
                        </div> */}
                        <Link to="/">
                            <img src={sibm} alt="" style={{ width: "100px" }} />
                        </Link>
                    </div>
                    <div className="navbar_right">
                        <GiHamburgerMenu
                            fontSize="20px"
                            onClick={() => setResponsiveMenu(!resposiveMenu)}
                        />
                    </div>
                </nav>

                {resposiveMenu && (
                    <div className="responsive_menu_wrapper animate__animated animate__fadeInDown">
                        <div className="close_btn">
                            <IoMdCloseCircleOutline
                                fontSize="30px"
                                onClick={() => setResponsiveMenu(!resposiveMenu)}
                            />
                        </div>
                        <ul class="resp_top_left">
                            <li>
                                <CiMail />
                                example@gmail.com
                            </li>
                            <li>
                                <HiOutlinePhone />
                                +1-202-555-0174
                            </li>
                        </ul>

                        <div className="resp_menu_list">
                            <ul className="resp_menu_items">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li onClick={() =>
                                    setServicesMenu(
                                        servicesMenu === "about-us" ? null : "about-us"
                                    )
                                }>
                                    <Link >About Us</Link>
                                    {servicesMenu === "about-us" ? <FiMinus /> : <GoPlus />}
                                </li>
                                {servicesMenu === "about-us" && (
                                    <ul className="responsive_submenu">
                                        <li><Link to="/course-detail/medical">About Us</Link></li>
                                        <li><Link to="/course-detail/engineering">Mission & Vision</Link></li>
                                        <li><Link to="/course-detail/mba">Admission</Link></li>
                                        <li><Link to="/course-detail/teaching">Faculty Corner</Link></li>
                                        <li><Link to="/course-detail/law">Approval & Affiliation</Link></li>
                                    </ul>
                                )}

                                <li onClick={() =>
                                    setServicesMenu(
                                        servicesMenu === "academics" ? null : "academics"
                                    )
                                }>
                                    <Link >Academics</Link>
                                    {servicesMenu === "academics" ? <FiMinus /> : <GoPlus />}
                                </li>
                                {servicesMenu === "academics" && (
                                    <ul className="responsive_submenu">
                                        <li onClick={() => setSubMenu(subMenu === "admission" ? "" : "admission")}><Link to="/admission">Admission</Link>
                                            {subMenu === "admission" ? <FiMinus /> : <GoPlus />}
                                        </li>
                                        {
                                            subMenu === "admission" &&
                                            <ul className="responsive_submenu_tab">
                                                <li><Link to="">Why Admission in SIBM</Link></li>
                                                <li><Link to="">Prospects</Link></li>
                                                <li><Link to="">Fees Structure</Link></li>
                                                <li><Link to="">Online Admission </Link></li>
                                                <li><Link to="">Download Admission Form</Link></li>
                                                <li><Link to="">Admission Rules and Regulations</Link></li>
                                            </ul>
                                        }



                                        <li><Link to="/affiliation">Affiliation</Link></li>
                                        <li><Link to="/approval">Letter Of Approval</Link></li>
                                        <li><Link to="/faculty">Faculty</Link></li>
                                        <li><Link to="/academics-calender">Academics Calender</Link></li>
                                        <li><Link to="/question-bank">Question Bank</Link></li>
                                    </ul>
                                )}
                                <li
                                    onClick={() =>
                                        setServicesMenu(
                                            servicesMenu === "administration" ? null : "administration"
                                        )
                                    }
                                >
                                    <Link>Administration</Link>
                                    {servicesMenu === "administration" ? <FiMinus /> : <GoPlus />}
                                </li>

                                {servicesMenu === "administration" && (
                                    <ul className="responsive_submenu">
                                        <li><Link to="/course-detail/medical">Admin Member</Link></li>
                                        <li><Link to="/course-detail/engineering">Governing Body</Link></li>

                                    </ul>
                                )}
                                <li
                                    onClick={() =>
                                        setServicesMenu(
                                            servicesMenu === "courses" ? null : "courses"
                                        )
                                    }
                                >
                                    <Link>Courses</Link>
                                    {servicesMenu === "courses" ? <FiMinus /> : <GoPlus />}
                                </li>

                                {servicesMenu === "courses" && (
                                    <ul className="responsive_submenu">
                                        <li><Link to="/course-detail/bba">BBA</Link></li>
                                        <li><Link to="/course-detail/bca">BCA</Link></li>

                                    </ul>
                                )}
                                <li>
                                    <Link to="/gallery">Gallery</Link>
                                </li>
                                <li><Link to="/testimonials">Testimonials</Link></li>
                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ResponsiveNavbar