import React, { useEffect, useState } from 'react'
import { FaFacebook, FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa'
import "../assets/styles/navbar.css"
import { Link, useLocation } from 'react-router-dom'
import logo from "../assets/images/logo.jpeg"
import { RiArrowDropDownLine } from 'react-icons/ri'
import { MdKeyboardArrowRight, MdOutlineArrowRight } from 'react-icons/md'

const Navbar = () => {

    const [currentTab, setCurrentTab] = useState("")
    const [subCurrentTab, setSubCurrentTab] = useState("")


    // Close dropdown on route change
    const handleLinkClick = () => {
        setCurrentTab(""); // Close the dropdown after clicking a link
    };

    const overMenu = () => {
        setCurrentTab("");
        setSubCurrentTab("")
    }


    return (
        <>

            <header>
                <div className="header-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <ul className='social_media_icons'>
                                    <li><FaFacebook /></li>
                                    <li><FaTwitterSquare /></li>
                                    <li><FaInstagramSquare /></li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="right_block">
                                    <p>English</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-middle">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-sm-12">
                                <div className="web_logo">
                                    <Link><em style={{ color: "green", fontSize: "24px", fontWeight: "bold" }}>
                                        <img src={logo} alt="" style={{ width: "100px" }} />
                                    </em></Link>
                                </div>
                            </div>
                            <div className="col-lg-9 col-sm-12">
                                <ul className="middle_menu_list">
                                    <li>
                                        <span>Email :</span>
                                        <strong>info@sibm.org</strong>
                                    </li>
                                    <li>|</li>
                                    <li>
                                        <span>Contact Number : </span>
                                        <strong>000000000</strong>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="main_header_list">
                                    <li><Link to="/">Home</Link></li>
                                    <li className='menu'><Link to="/about-us" onMouseEnter={() => setCurrentTab("about-us")}>About Us <RiArrowDropDownLine fontSize="20px" /></Link>

                                        {
                                            currentTab === "about-us" &&
                                            <ul className="dropdown-menu" onMouseLeave={overMenu}>
                                                <li ><Link to="/about-us">About Us</Link>

                                                </li>
                                                <li><Link to="/mission-vision">Mission & Vision</Link></li>
                                                <li><Link to="">Admission</Link></li>
                                                <li><Link to="">Faculty Corner</Link></li>
                                                <li><Link to="">Approval & Affiliation</Link></li>
                                            </ul>

                                        }
                                    </li>
                                    <li className='menu'><Link onMouseEnter={() => setCurrentTab("academics")}>Academics <RiArrowDropDownLine fontSize="20px" /></Link>

                                        {
                                            currentTab === "academics" && <ul className="dropdown-menu" onMouseLeave={overMenu}>

                                                <li style={{ position: "relative" }}><Link onMouseEnter={() => setSubCurrentTab("admission")} style={{ display: "flex", justifyContent: "space-between" }} >Admission <MdOutlineArrowRight fontSize="25px" /> </Link>
                                                    {
                                                        subCurrentTab === "admission" &&
                                                        <ul className="sub-dropdown-menu" onMouseLeave={overMenu}>
                                                            <li><Link to="">Why Admission in SIBM</Link></li>
                                                            <li><Link to="">Prospects</Link></li>
                                                            <li><Link to="">Fees Structure</Link></li>
                                                            <li><Link to="">Online Admission </Link></li>
                                                            <li><Link to="">Download Admission Form</Link></li>
                                                            <li><Link to="">Admission Rules and Regulations</Link></li>
                                                        </ul>
                                                    }
                                                </li>
                                                <li><Link >Affiliation Letter</Link></li>
                                                <li><Link >Letter of Approval</Link></li>
                                                <li><Link >Faculty</Link></li>
                                                <li><Link >Academic Calender</Link></li>
                                                <li><Link>Question Bank</Link></li>
                                            </ul>
                                        }
                                    </li>
                                    <li className='menu'><Link onMouseEnter={() => setCurrentTab("administration")}>Administration <RiArrowDropDownLine fontSize="20px" /></Link>
                                        {
                                            currentTab === "administration" &&
                                            <ul className="dropdown-menu" onMouseLeave={overMenu}>

                                                <li><Link >Admin Member</Link></li>
                                                <li><Link >Governing Body</Link></li>

                                            </ul>
                                        }
                                    </li>
                                    <li className='menu' onMouseEnter={() => setCurrentTab("our-courses")}><Link className='d-flex align-items-center'>Our Courses <RiArrowDropDownLine fontSize="20px" /></Link>

                                        {
                                            currentTab === "our-courses" && <ul className="dropdown-menu" onMouseLeave={overMenu}>
                                                <li><Link to="/course-detail/bba">BBA</Link></li>
                                                <li><Link to="/course-detail/bca">BCA</Link></li>

                                            </ul>
                                        }
                                    </li>



                                    <li><Link to="/gallery">Gallery</Link></li>
                                    <li><Link to="/testimonials">Testimonials</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div >
            </header >
        </>
    )
}

export default Navbar