import React, { useState } from 'react'
import "../../assets/styles/course.css"
import course from "../../assets/images/course.jpg"
import { TiTickOutline } from 'react-icons/ti'
import { CiClock1 } from 'react-icons/ci'
import { MdMenuBook, MdOutlineMenu, MdSchedule } from 'react-icons/md'
import { GrCertificate } from 'react-icons/gr'
import { IoPeople } from 'react-icons/io5'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Medical = () => {
    const [selectCurriTab, setSelectCurriTab] = useState("modern-curriculum")


    const curriculum = [
        {
            year: 'Year 1',
            semester: 'Fall',
            courses: ['Calculus I', 'General Physics I', 'Introduction to Engineering', 'General Chemistry', 'Programming for Engineers', 'Engineering Graphics'],
        },
        {
            year: 'Year 1',
            semester: 'Spring',
            courses: ['Calculus II', 'General Physics II', 'Introduction to Engineering', 'General Chemistry', 'Programming for Engineers', 'Engineering Graphics'],
        },
        {
            year: 'Year 2',
            semester: 'Fall',
            courses: ['Calculus III', 'Differential Equations', 'Mechanics of Materials', 'Circuit Analysis', 'Materials Science'],
        },
        // Add more semesters as needed
    ]
    return (
        <>
            <div className="course_banner">
                <div className="course_wrapper_overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="content">
                                <h1>Medical</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about_course padding-lg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="course_img">
                                <img src={course} alt="" width="100%" />

                                <div className="enquiry_now">
                                    <div className="inner">
                                        <h3>Enquiry Now</h3>

                                        <form action="">
                                            <span className='row_1'>
                                                <input type="text" placeholder='Name' />
                                            </span>
                                            <span className='row_1'>
                                                <input type="text" placeholder='Email' />
                                            </span>
                                            <span className='row_1'>
                                                <input type="text" placeholder='Number' />
                                            </span>
                                            <p>  <input type="submit" value="Enquire now" className="wpcf7-form-control wpcf7-submit enquire_btn" /><span className="ajax-loader" ></span>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="course_desc">
                                <h2>COURSE DESCRIPTION</h2>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt, totam? Vel officia molestias, aut perspiciatis facere hic rem, iusto id officiis exercitationem blanditiis necessitatibus consequatur voluptatem ratione, non corrupti dignissimos? Nobis minus sequi quae. Itaque et veritatis praesentium aperiam eius?</p>
                            </div>

                            <div className="course_speci">
                                <h2>CERTIFICATION</h2>
                                <ul class="cert-list clearfix">
                                    <li> <TiTickOutline />Lorem Ipsum is simply dummy text of the</li>
                                    <li> <TiTickOutline />Lorem Ipsum is simply dummy </li>
                                    <li> <TiTickOutline />Lorem Ipsum is simply dummy </li>
                                    <li><TiTickOutline /> Lorem Ipsum is simply dummy text of the</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="padding-lg" id='eligiblity'>
                <div className="container">
                    <div className='courses_detail'>
                        <div class="duration dur">

                            <div class="icon"><CiClock1 /></div>
                            <div class="detail"> <span>Duration</span><br /><strong> 2 YEAR</strong> </div>

                        </div>
                        <div class="duration eligible">

                            <div class="icon"><MdOutlineMenu /> </div>
                            <div class="detail"> <span>ELIGIBILITY:</span><br /><strong>Graduation/DBA/
                                <br />  Diploma/Equivalent
                            </strong> </div>

                        </div>
                        <div class="duration course">

                            <div class="detail"> <span>COURSE FEE:</span><br /><strong>$2500</strong> </div>

                        </div>
                        <div class="duration course">
                            <a class="btn">apply now <span class="icon-more-icon"></span></a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="course_detail_tab padding-lg">
                <div className="container">
                    <ul class="nav nav-tabs course-tab" id="myTabs" role="tablist">
                        <li role="presentation" className={selectCurriTab === "modern-curriculum" ? "active" : ""} onClick={() => setSelectCurriTab("modern-curriculum")}>
                            <a href="#curriculam" id="curriculam-tab" role="tab" data-toggle="tab" aria-controls="curriculam" aria-expanded="true">

                                <MdMenuBook fontSize="30px" />

                                <div class="block">MODERN CURRICULUM</div>
                            </a>
                        </li>
                        <li role="presentation" className={selectCurriTab === "fee-schedule" ? "active" : ""} onClick={() => setSelectCurriTab("fee-schedule")}>
                            <a href="#schedule" id="-tab" role="tab" data-toggle="tab" aria-controls="" aria-expanded="false">

                                <MdSchedule fontSize="30px" />
                                <div class="block">FEE SCHEDULE</div>
                            </a>
                        </li>
                        <li role="presentation" className={selectCurriTab === "sem" ? "active" : ""} onClick={() => setSelectCurriTab("sem")}>
                            <a href="#semester" id="-tab" role="tab" data-toggle="tab" aria-controls="" aria-expanded="false">

                                <GrCertificate fontSize="30px" />

                                <div class="block">SEMESTER I TO IV</div>
                            </a>
                        </li>
                        <li role="presentation" className={selectCurriTab === "teachers" ? "active" : ""} onClick={() => setSelectCurriTab("teachers")}>
                            <a href="#teachers" id="-tab" role="tab" data-toggle="tab" aria-controls="" aria-expanded="false">

                                <IoPeople fontSize="30px" />
                                <div class="block">Main Teachers</div>
                            </a>
                        </li>
                    </ul>


                    {selectCurriTab === "modern-curriculum" &&

                        <div className="table-container">
                            <table className="curriculum-table">
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Semester</th>
                                        <th>Courses</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {curriculum.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.year}</td>
                                            <td>{item.semester}</td>
                                            <td>
                                                <ul>
                                                    {item.courses.map((course, idx) => (
                                                        <li key={idx}>{course}</li>
                                                    ))}
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }


                    {/*  */}
                    {selectCurriTab === "fee-schedule" &&

                        <div className="table-container">
                            <table className="curriculum-table">
                                <thead>
                                    <tr>
                                        <th>Fees</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr >
                                        <td>One Time Payment</td>
                                        <td><strong>$2500</strong></td>

                                    </tr>


                                    <tr >
                                        <td>Short term Installment Plan</td>
                                        <td><strong>$2700</strong></td>

                                    </tr>

                                    <tr >
                                        <td>Short term Installment Plan</td>
                                        <td><strong>$3000</strong></td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    }


                    {
                        selectCurriTab === "sem" &&
                        <div className="sem_container">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="sem_container_left">
                                        <h3>Lorem Ipsum is simply dummy</h3>
                                        <ul>
                                            <li><TiTickOutline /> Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industry's standard</li>
                                            <li> <TiTickOutline /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum aliquid fugiat delectus non perferendis saepe expedita in, sed quasi? Laborum.</li>
                                            <li> <TiTickOutline /> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vitae perferendis placeat doloribus fugit deserunt fugiat aperiam, omnis suscipit nesciunt quo.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="sem_container_left">
                                        <h3 style={{ marginTop: "20px" }}>Lorem Ipsum is simply dummy</h3>
                                        <ul>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industry's standard</li>

                                        </ul>

                                        <h3 style={{ marginTop: "20px" }}>Lorem Ipsum is simply dummy</h3>
                                        <ul>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industry's standard</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                    {
                        selectCurriTab === "teachers" &&
                        <div className="teacher_container">
                            <div className="row">
                                <div className="col-lg-3 col-sm-12">
                                    <div className="teacher_Card">
                                        <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/teacher1.jpg" alt="" width="123" height="124" />
                                        <h3>Bruno Acselrad</h3>
                                        <span class="designation">Math Teacher</span>
                                        <p class="equal-hight">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="teacher_Card">
                                        <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/teacher1.jpg" alt="" width="123" height="124" />
                                        <h3>Bruno Acselrad</h3>
                                        <span class="designation">Math Teacher</span>
                                        <p class="equal-hight">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="teacher_Card">
                                        <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/teacher1.jpg" alt="" width="123" height="124" />
                                        <h3>Bruno Acselrad</h3>
                                        <span class="designation">Math Teacher</span>
                                        <p class="equal-hight">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="teacher_Card">
                                        <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/teacher1.jpg" alt="" width="123" height="124" />
                                        <h3>Bruno Acselrad</h3>
                                        <span class="designation">Math Teacher</span>
                                        <p class="equal-hight">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div >


            <section class="how-apply padding-lg">
                <div class="container">
                    <h2>
                        <span>There are many ways to learn</span>
                        How to Apply
                    </h2>
                    <ul class="row">
                        <li class="col-sm-12 col-lg-4" style={{ padding: "20px" }}>
                            <div class="icon-block">
                                <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/registration-ico.png" alt="" />
                            </div>
                            <h3 className='text-center'>REGISTRATION</h3>
                            <p className='text-center'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                        </li>
                        <li class="col-sm-12 col-lg-4" style={{ padding: "20px" }}>
                            <div class="icon-block">
                                <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/doc-ico.png" alt="" />
                            </div>
                            <h3 className='text-center'>DOCUMENTATION</h3>
                            <p className='text-center'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                        </li>
                        <li class="col-sm-12 col-lg-4" style={{ padding: "20px" }}>
                            <div class="icon-block">
                                <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/get-start-ico.png" alt="" />
                            </div>
                            <h3 className='text-center'>Get started now</h3>
                            <p className='text-center'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                        </li>
                    </ul>
                    <div class="button-wrapper"> <a href="https://shtheme.com/demosd/edumart/?page_id=690" class="btn">apply now <span class="icon-more-icon"></span></a> </div>
                </div>
            </section>


            <section class="testimonial padding-lg">

                <div class="container">
                    <div class="wrapper">
                        <h2 className='why-choose-title text-center' style={{ color: "#fff" }}>Alumini Testimonials </h2>

                    </div>

                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="testi_texts">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, animi voluptatibus soluta et assumenda sapiente officia nam esse voluptatum vitae, nemo eaque. Fugit magnam, quo porro totam maiores soluta assumenda magni quisquam similique eos dignissimos, odio dolorem facilis blanditiis sequi officiis itaque obcaecati. Earum voluptatibus architecto ratione delectus nulla inventore esse cumque doloribus provident quasi autem iusto dolorem, quo impedit.</p>
                                <p className="test_name">
                                    Sayli
                                </p>
                            </div>
                            <div id="bx-pager">
                                <a data-slide-index="0" href="" class="">
                                    <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/testimonial-thumb1.jpg" class="img-circle" alt="" />
                                </a>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testi_texts">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, animi voluptatibus soluta et assumenda sapiente officia nam esse voluptatum vitae, nemo eaque. Fugit magnam, quo porro totam maiores soluta assumenda magni quisquam similique eos dignissimos, odio dolorem facilis blanditiis sequi officiis itaque obcaecati. Earum voluptatibus architecto ratione delectus nulla inventore esse cumque doloribus provident quasi autem iusto dolorem, quo impedit.</p>
                                <p className="test_name">
                                    Ravi Kumar
                                </p>
                            </div>
                            <div id="bx-pager">
                                <a data-slide-index="0" href="" class="">
                                    <img decoding="async" src="https://shtheme.com/demosd/edumart/wp-content/uploads/2022/12/testimonial-thumb2.jpg" class="img-circle" alt="" />
                                </a>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testi_texts">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, animi voluptatibus soluta et assumenda sapiente officia nam esse voluptatum vitae, nemo eaque. Fugit magnam, quo porro totam maiores soluta assumenda magni quisquam similique eos dignissimos, odio dolorem facilis blanditiis sequi officiis itaque obcaecati. Earum voluptatibus architecto ratione delectus nulla inventore esse cumque doloribus provident quasi autem iusto dolorem, quo impedit.</p>
                                <p className="test_name">
                                    Riya
                                </p>
                            </div>
                        </SwiperSlide>


                    </Swiper>
                </div>
            </section>

        </>
    )
}

export default Medical