import React from 'react'
import "../assets/styles/testimonials.css"
import review from "../assets/images/review.png"

const Testimonials = () => {
    return (
        <>
            <div className="testimonial_banner">

                <div className="contact_wrapper_overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="content">
                                <h1>Testimonials</h1>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <section className="gallery mtb-40 padding-lg">
                <div className="container">
                    <div className="gallery_sec text-center">

                        <h1>Testimonials</h1>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="testi_card">
                                <div className="card_img">
                                    <img src={review} alt="" style={{ width: "100px" }} />
                                </div>
                                <div className="card-body">
                                    <p>The instructors at this institute are incredibly knowledgeable and approachable. They genuinely care about students' progress and provide excellent guidance. The campus is well-equipped with modern facilities, making it a fantastic place to study!</p>
                                </div>
                                <div className="card-footer">
                                    <strong>Sonam T.</strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="testi_card">
                                <div className="card_img">
                                    <img src={review} alt="" style={{ width: "100px" }} />
                                </div>
                                <div className="card-body">
                                    <p>I’ve had an amazing experience at this institute. The atmosphere is very positive, and there is a strong focus on both academic and personal growth. The teachers are supportive, and the extracurricular activities help in building skills beyond the classroom.</p>
                                </div>
                                <div className="card-footer">
                                    <strong>Roma</strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="testi_card">
                                <div className="card_img">
                                    <img src={review} alt="" style={{ width: "100px" }} />
                                </div>
                                <div className="card-body">
                                    <p>The curriculum here is practical and geared towards preparing students for real-world challenges. I’ve learned so much that I can directly apply in my career. The institute also offers excellent internship opportunities that have been invaluable for gaining experience.</p>
                                </div>
                                <div className="card-footer">
                                    <strong>Suresh</strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="testi_card">
                                <div className="card_img">
                                    <img src={review} alt="" style={{ width: "100px" }} />
                                </div>
                                <div className="card-body">
                                    <p>The staff and faculty are extremely supportive and always ready to help. The teaching quality is top-notch, and the institute offers a wide range of programs to choose from. I would highly recommend it to anyone looking for quality education.</p>
                                </div>
                                <div className="card-footer">
                                    <strong>Jay</strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="testi_card">
                                <div className="card_img">
                                    <img src={review} alt="" style={{ width: "100px" }} />
                                </div>
                                <div className="card-body">
                                    <p>Enrolling in this institute was the best decision I ever made. The infrastructure is modern, the faculty is experienced, and the overall environment fosters learning. I’ve made great progress in my studies and feel confident about my future!</p>
                                </div>
                                <div className="card-footer">
                                    <strong>Vencky</strong>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </section>


        </>
    )
}

export default Testimonials