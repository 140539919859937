import React, { useState } from 'react'
import g1 from "../assets/images/gallery/g1.jpg"
import g2 from "../assets/images/gallery/g2.jpg"
import g3 from "../assets/images/gallery/g3.jpg"
import g4 from "../assets/images/gallery/g4.jpg"
import g5 from "../assets/images/gallery/g5.jpg"
import g6 from "../assets/images/gallery/g6.jpg"
import g7 from "../assets/images/gallery/g7.jpg"
import g8 from "../assets/images/gallery/g8.jpg"
import g9 from "../assets/images/gallery/g9.jpg"
import g10 from "../assets/images/gallery/g10.jpg"
import "../assets/styles/gallery.css"

const Gallery = () => {

    const [modalImage, setModalImage] = useState("")
    const [currentIndex, setCurrentIndex] = useState("")


    const galleryImages = [
        {
            img: g1
        },
        {
            img: g2
        },
        {
            img: g3
        },
        {
            img: g4
        },
        {
            img: g5
        },
        {
            img: g6
        },
        {
            img: g7
        },
        {
            img: g8
        },
        {
            img: g9
        },
        {
            img: g10
        },
    ]


    const openModal = (index) => {
        setModalImage(galleryImages[index].img);
        console.log(modalImage)
        setCurrentIndex(index)
    }


    const closeModal = () => {
        setModalImage("");
    };


    const prevImage = () => {
        if (currentIndex === 0) {
            // If current index is the first image, wrap around to the last image
            const lastIndex = galleryImages.length - 1;
            setModalImage(galleryImages[lastIndex].img);
            setCurrentIndex(lastIndex);
        } else {
            // Otherwise, go to the previous image
            const newIndex = currentIndex - 1;
            setModalImage(galleryImages[newIndex].img);
            setCurrentIndex(newIndex);
        }
    }


    const nextImage = () => {
        if (currentIndex === galleryImages.length - 1) {
            // If current index is the last image, wrap around to the first image
            setModalImage(galleryImages[0].img);
            setCurrentIndex(0);
        } else {
            // Otherwise, go to the next image
            const newIndex = currentIndex + 1;
            setModalImage(galleryImages[newIndex].img);
            setCurrentIndex(newIndex);
        }

    }


    return (
        <>
            <section className="gallery_wrapper">
                <div className="gallery_wrapper_overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="content">
                                <h1>Gallery</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="gallery mtb-40 padding-lg">
                <div className="container">
                    <div className="gallery_sec text-center">
                        <span>Our campus have a lot to offer for our students
                        </span>
                        <h1>TAKE A CAMPUS TOUR</h1>
                    </div>
                    <div className="row">
                        {
                            galleryImages.map((item, index) => {
                                return (
                                    <div className="col-lg-3 col-sm-12">
                                        <div className="gallery_card">
                                            <img src={item.img} alt="" srcset="" style={{ width: "100%" }} onClick={() => openModal(index)} />


                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>


                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-8 col-sm-12">
                            {modalImage && <div id="imageModal" class="imgmodal">
                                <span class="close" onClick={closeModal}>&times;</span>
                                <img class="modal-content" src={modalImage} id="modalImage" />
                                <div class="caption" id="caption"></div>
                                <button class="prev" onclick={prevImage}>&#10094; Prev</button>
                                <button class="next" onclick={nextImage}>Next &#10095;</button>
                            </div>}
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}

export default Gallery